import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  row: {
    display: 'block',
    flexDirection: 'column',
    width: '100%',
    background: theme.colors.bgLight,
    border: `1px solid ${theme.colors.bgMedium}`,
    lineHeight: 1,
    padding: '1.5rem',
    marginBottom: '1rem',
    position: 'relative',
    transform: 'scale(1)',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      display: 'table-row',
      padding: 0,
      marginBottom: 0,
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  col: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 400,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      position: 'relative',
      display: 'table-cell',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    '& span': {
      fontSize: '1.4rem',
      width: 'auto',
      display: 'block',
      lineHeight: 1.9,
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        display: 'initial',
      },
    },
  },
  shipmentState: {
    display: 'inline-block',
    marginLeft: '0.4rem',
  },
  arrow: {
    position: 'absolute',
    top: '2.3rem',
    right: '1.4rem',
    pointerEvent: 'none',
    width: '0.9rem',
    color: theme.text,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      display: 'none',
    },
  },
  link: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  noImage: {
    '& div': {
      background: '#dddddd',
    },
    '& img': {
      width: '50% !important',
    },
  },
  thumbnail: {
    float: 'left',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: '11.2rem',
    },
  },
  thumbnailImageContainer: {
    margin: '0 1.6rem 0 0',
    height: 'auto',
    padding: 0,
    width: '8rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      margin: '1.6rem',
    },
    '& div': {
      height: 0,
      padding: 0,
      paddingBottom: ratioCalc(theme.config.ratioThumbnail),
      overflow: 'hidden',
      position: 'relative',
      display: 'block',
      margin: 0,
    },
    '& img': {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
    },
  },
  thumbnailImage: {
  },
  orderNumber: {
    marginBottom: '6.6rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontWeight: 600,
    paddingRight: '2rem',
    '& span': {
      lineHeight: 1.142,
      wordBreak: 'break-word',
    },
  },
  orderState: {
    fontWeight: 600,
    clear: 'both',
  },
  shipmentStyle: {
    textAlign: 'right',
    '& span': {
      lineHeight: '1.4 !important',
      marginBottom: '0.8rem',
    },
  },
  confirmed: {
    color: theme.colors.confirm,
  },
  pending: {
    color: theme.colors.active,
  },
  completed: {
    color: theme.colors.active,
  },
  cancelled: {
    color: theme.colors.alert,
  },
  declined: {
    color: theme.colors.alert,
  },
  mdHidden: {
    display: 'block',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    mdHidden: {
      display: 'none !important',
    },
  },
}))

export default useStyles
