/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTheme } from 'react-jss'
import moment from 'moment'
import { combineClassNames } from '../../../../helpers'
import {
  StateLabel,
  CurrencyFormat,
  Link,
} from '../../../../ui'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'
import ArrowDownIcon from '../../../../assets/icons/icon_order_right_arrow.svg'
import useStyles from './orders-row-style'

const OrderRowView = ({ order, t }) => {
  // hook
  const theme = useTheme()
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  const thumbnail = useMemo(() => {
    if (_.isEmpty(order.shipments[0].orderLines)) {
      return _.get(order, 'orderLineProperties.0.colorOption.images.0.versions.webMedium', placeholder)
    }
    return _.get(order, 'shipments.0.orderLines.0.colorOption.images.0.versions.webMedium', placeholder)
  }, [order])
  const noImage = _.isEmpty(_.get(order, 'shipments.0.orderLines.0.colorOption.images.0.versions.webMedium'))
                && _.isEmpty(_.get(order, 'orderLineProperties.0.colorOption.images.0.versions.webMedium'))
  // style
  const styles = useStyles()

  return (
    <div className={styles.row}>
      <img className={styles.arrow} src={ArrowDownIcon} alt="" />
      <div className={combineClassNames([styles.col, styles.thumbnail])}>
        <div
          className={
            combineClassNames([styles.thumbnailImageContainer, noImage && styles.noImage])
          }
        >
          <div>
            <img className={styles.thumbnailImage} src={thumbnail} alt="" />
          </div>
        </div>
        <Link to={`/account/order/${_.get(order, 'referenceNumber', '')}/`} className={styles.link} />
      </div>
      <div className={combineClassNames([styles.col, styles.orderNumber])}>
        <span className={styles.mdHidden}>
          {t('screens.account.orders.table.order')}
          :
        </span>
        <span>{_.get(order, 'referenceNumber', '--')}</span>
        <Link to={`/account/order/${_.get(order, 'referenceNumber', '')}/`} className={styles.link} />
      </div>
      <div className={combineClassNames([styles.col, styles.orderState])}>
        <span className={styles.mdHidden}>
          {t('screens.account.orders.table.orderStatus')}
          :
        </span>
        <span>
          <StateLabel state={_.get(order, 'state')}>
            {t('screens.orderDetail.detailsOrder.state', {
              context: _.kebabCase(_.get(order, 'state')),
            })}
          </StateLabel>
        </span>
        <Link to={`/account/order/${_.get(order, 'referenceNumber', '')}/`} className={styles.link} />
      </div>
      <div className={combineClassNames([styles.col, styles.date])}>
        <span className={styles.mdHidden}>
          {t('screens.account.orders.table.date')}
          :
        </span>
        <span>{moment(_.get(order, 'createdAt', '')).format('MM/DD/YYYY')}</span>
        <Link to={`/account/order/${_.get(order, 'referenceNumber', '')}/`} className={styles.link} />
      </div>
      <div className={combineClassNames([styles.col, styles.shipments])}>
        <span className={styles.mdHidden}>
          {t('screens.account.orders.table.shipment')}
          :
        </span>
        <span className={styles.shipmentStyle}>
          {/* [TODO] Support multi-shipment */}
          {t('screens.orderDetail.delivery.type', {
            context: _.kebabCase(_.get(order, 'shipments.0.deliveryType.code')),
          })}
          <span className={styles.shipmentState}>
            <StateLabel state={_.get(order, 'shipments.0.state')}>
              {t('screens.orderDetail.delivery.state', {
                context: _.kebabCase(_.get(order, 'shipments.0.state')),
              })}
            </StateLabel>
          </span>
        </span>
        <Link to={`/account/order/${_.get(order, 'referenceNumber', '')}/`} className={styles.link} />
      </div>
      <div className={combineClassNames([styles.col, styles.price])}>
        <span className={styles.mdHidden}>
          {t('screens.account.orders.table.total')}
          :
        </span>
        <CurrencyFormat value={_.get(order, 'priceDetails.orderTotalPrice', _.get(order, 'totalPrice', ''))} />
        <Link to={`/account/order/${_.get(order, 'referenceNumber', '')}/`} className={styles.link} />
      </div>
    </div>
  )
}

export default OrderRowView
