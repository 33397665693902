import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '3rem',
    '& header': {
      display: 'none',
      height: '3.5rem',
      '& div': {
        heigth: '3rem',
        display: 'table-cell',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '1.4rem',
        fontWeight: 600,
        '&:first-child': {
          width: '11.2rem',
        },
      },
    },
  },

  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    table: {
      display: 'table',
      borderCollapse: 'collapse',
      '& header': {
        display: 'table-row',
      },
    },
  },
}))

export default useStyles
