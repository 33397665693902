/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import useStyles from './orders-table-style'
import OrderRow from '../orders-row/orders-row-view'
import Spinner from '../../../../ui/spinner'

const OrderTableView = ({
  t,
  orders,
  loading,
  currency,
  hasMoreOrders,
  isOrdersReady,
  onFetchOrdersNextPage,
}) => {
  const days = _.map(orders, (order) => ({
    id: order.id,
    day: moment(_.get(order, 'createdAt', '')).format('MM/DD/YYYY'),
  }))
  const { table } = useStyles()
  return (
    <>
      {isOrdersReady ? (
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={onFetchOrdersNextPage}
          hasMore={hasMoreOrders}
          loader={<Spinner />}
          useWindow
        >
          <section className={table}>
            <header>
              <div>{' '}</div>
              <div>{t('screens.account.orders.table.order')}</div>
              <div>{t('screens.account.orders.table.orderStatus')}</div>
              <div>{t('screens.account.orders.table.date')}</div>
              <div>{t('screens.account.orders.table.shipment')}</div>
              <div>{t('screens.account.orders.table.total')}</div>
            </header>
            {
            orders.length > 0
              && orders.map((order) => <OrderRow key={order.number} t={t} order={order} currency={currency} />)
          }

          </section>
        </InfiniteScroll>
      ) : null}
    </>
  )
}

export default OrderTableView
