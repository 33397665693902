import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmptyScreen,
  Layout,
  Loading,
  SEO,
} from '../../../ui'
import useStyles from './account-orders-style'
import Ordertable from './orders-table/orders-table-view'

function AccountOrdersView({
  currency,
  loading,
  orders,
  seoTitle,
  user,
  pageReady,
  hasMoreOrders,
  isOrdersReady,
  onFetchOrdersNextPage,
}) {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <Layout login user={user}>
      <SEO title={seoTitle} />
      {
        !pageReady && <Loading />
      }

      <div className={styles.containerFluid}>
        <div className={styles.container}>
          {
            _.isEmpty(orders) && !loading ? (
              <EmptyScreen
                message={t('screens.account.orders.empty.message')}
                button={{
                  text: t('screens.account.orders.empty.buttons.shopNow'),
                  navigate: '/products/',
                }}
              />
            ) : (
              <>
                <div>
                  <h5 className={styles.title}>{t('screens.account.orders.title')}</h5>
                  <h6 className={styles.subtitle}>{t('screens.account.orders.subTitle')}</h6>
                </div>
                <Ordertable
                  t={t}
                  orders={orders}
                  currency={currency}
                  hasMoreOrders={hasMoreOrders}
                  isOrdersReady={isOrdersReady}
                  onFetchOrdersNextPage={onFetchOrdersNextPage}
                  loading={loading}
                />
                <p className={styles.messageStyle}>{t('screens.account.orders.message')}</p>
              </>
            )
          }
        </div>
      </div>

    </Layout>
  )
}

export default AccountOrdersView
