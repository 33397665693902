import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    padding: [[30, 0]],
    width: '100%',
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem 3rem',
  },
  title: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: 0,
  },
  subtitle: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    fontWeight: 400,
    color: theme.muted,
    marginBottom: '3rem',
    marginTop: '0.4rem',
  },
  messageStyle: {
    width: '25rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    margin: '0 auto',
    lineHeight: 1.2,
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    containerFluid: {
      padding: [[60, 0, 200]],
      width: '100%',
      textAlign: 'left',
    },
    messageStyle: {
      width: '100%',
    },
  },
}))

export default useStyles
